import React, { FunctionComponent } from 'react';
import Dialog from '@mui/material/Dialog';
import { isLight } from '../../common/helpers';
import { Theme, useTheme } from '@mui/material/styles';
import { KnownBreakpoints } from '../../common/constants';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoanAgreement from './LoanAgreement';

const styles = {
    paper: (theme: Theme, isMobile: boolean) => ({
        maxWidth: isMobile ? '100%' : '1024px',
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.main,
        color: (isLight(theme)
            ? theme.palette.common.black
            : theme.palette.common.white) + ' !important',
        borderRadius: 0,
        backgroundImage: 'none',
        width: isMobile ? '100%' : 'auto',
        height: isMobile ? '100%' : 'auto',
        minWidth: isMobile ? '100%' : '50%',
        lineHeight: '1.2em',
        ...(isMobile ? {
            margin: 0,
            maxHeight: '100%',
        } : {}),
        margin: 0,
        padding: 0,
    }),
};

export interface LoanAgreementModalProps {
    open: boolean;
    onClose: () => void;
    title?: string;
    loanDocument?: string;
    scrollPoint?: any;
    loanId?: string;
    customerId?: string;
    id?: string;
}

const LoanAgreementModal: FunctionComponent<
    LoanAgreementModalProps
> = props => {
    const {
        open,
        onClose,
        title,
        loanDocument,
        scrollPoint,
        id,
        loanId,
        customerId,
    } = props;
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );

    return <Dialog
        open={ open }
        onClose={ onClose }
        PaperProps={{ sx: styles.paper(theme, isMobile) }}
        keepMounted={ !!loanDocument }
    >
        <LoanAgreement
            title={ title }
            actions={ true }
            id={ id || 'modal' }
            loanDocument={ loanDocument }
            scrollPoint={ scrollPoint }
            onClose={ onClose }
            loanId={ loanId }
            customerId={ customerId }
        />
    </Dialog>;
};

export default LoanAgreementModal;
