import React, {
    Fragment,
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { isLight } from '../common/helpers';
import { TABLE_BREAKPOINT } from '../common/constants';
import transformTable from '../common/helpers/transformTable';
import { fireGtmEvent, GtmEvent } from '../common/gtmEvents';

const styles = {
    tableContainer: (
        theme: Theme,
        height?: string,
        isPaymentPlan?: boolean,
    ) => ({
        width: '100%',
        height: height ? height : 'unset',
        [theme.breakpoints.down(TABLE_BREAKPOINT)]: {
            border: isPaymentPlan ? `1px solid` : 'none !important',
        },
    }),
    table: (theme: Theme) => ({
        [theme.breakpoints.down(TABLE_BREAKPOINT)]: {
            width: 'calc(100% - 5px)',
        },
    }),
    bodyRow: (
        theme: Theme,
        isPaymentPlan?: boolean,
        isReversed?: boolean,
    ) => ({
        backgroundColor: isPaymentPlan && isReversed
            ? isLight(theme)
                ? 'rgb(254,237,237)'
                : 'rgb(159,66,66)'
            : 'transparent',
        [theme.breakpoints.down(TABLE_BREAKPOINT)]: {
            border: isPaymentPlan
                ? `1px solid ${ isLight(theme)
                    ? '#333'
                    : theme.palette.info.main
                } !important`
                : 'none',
        },
    }),
    tableCell: (
        theme: Theme,
        isPaymentPlan?: boolean,
        status?: any,
    ) => ({
        padding: '10px',
        borderBottom: isPaymentPlan ? `1px solid ${
            isLight(theme)
                ? theme.palette.action.selected
                : theme.palette.info.main
        } !important` : 'undefined',
        color: status
            && (status === 'Returned' || status === 'Cleared')
            && isPaymentPlan
            ? status === 'Returned'
                ? theme.palette.error.main
                : theme.palette.success.main
            : theme.palette.text.primary,
        '&::before': {
            [theme.breakpoints.down(TABLE_BREAKPOINT)]: {
                width: '100%',
                backgroundColor: isLight(theme) ? '#D5ECF4' : '#13345E',
                padding: '8px',
            },
        },
        [theme.breakpoints.down(TABLE_BREAKPOINT)]: {
            padding: '5px 9px 9px 7px',
            textAlign: 'center',
            alignItems: 'center',
            gap: '8px',
        },
    }),
    expButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
    },
    expButton: (theme: Theme) => ({
        padding: '6px 9px 6px 14px',
        backgroundColor: isLight(theme)
            ? theme.palette.action.active
            : theme.palette.info.main
        ,
        borderRadius: '0 0 6px 6px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    }),
    expButtonText: (theme: Theme) => ({
        ...theme.typography.body2,
        lineHeight: '14px',
        whiteSpace: 'nowrap',
        marginRight: '5px',
        color: isLight(theme)
            ? theme.palette.text.primary
            : theme.palette.secondary.dark
        ,
    }),
};

export type Row = { [key: string]: string | number | React.JSX.Element };
export type Columns = { [key: string]: { title: string; width?: string } };
export interface DataTableProps {
    data: Row[];
    columns: Columns;
    height?: string;
    expandable?: boolean;
    isPaymentSchedule?: boolean;
    headBackgroundColor?: string;
    isESigPaymentSchedule?: boolean;
    isPaymentPlanDetails?: boolean;
    sx?: any;
    onExpand?: (expanded: boolean) => void;
}

const DataTable: FunctionComponent<DataTableProps> = ({
    data,
    columns,
    height,
    expandable,
    isPaymentSchedule,
    headBackgroundColor,
    isESigPaymentSchedule,
    sx,
    onExpand,
    isPaymentPlanDetails,
}) => {
    const [exp, setExp] = useState(false);
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(theme.breakpoints.down(TABLE_BREAKPOINT));

    const isCollapsable = data.length > 4;
    const dataMapped = expandable && isCollapsable ? data.slice(0, 3) : data;

    useEffect(() => {
        if (!isMobile) return;

        const table = document.querySelector('table');
        transformTable(table);
    }, [isMobile]);

    // noinspection JSXDomNesting
    return <Fragment>
        <TableContainer
            component={ Box }
            sx={{
                ...styles.tableContainer(theme, height, isPaymentPlanDetails),
                ...(sx ? typeof sx === 'function' ? sx(theme) : sx : {}),
            }}
        >
            <Table sx={ styles.table }>
                { expandable && isCollapsable && <colgroup>
                    <col style={{ width: '30%' }}/>
                    <col style={{ width: '30%' }}/>
                    <col style={{ width: '40%' }}/>
                </colgroup> }
                <TableHead sx={{ backgroundColor: headBackgroundColor }}>
                    <TableRow>
                        { Object.values(columns).map(({ title, width }) => (
                            <TableCell
                                key={ title }
                                width={ width || 'inherit' }
                                sx={{
                                    ...styles.tableCell,
                                    fontWeight: 700,
                                    padding: '10px',
                                    lineHeight: '14px',
                            }}
                            >
                                { title }
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                { data.length !== 0
                    ? <TableBody>
                        { dataMapped.map((item, index) => (
                            <TableRow key={ index } sx={ styles.bodyRow(
                                theme,
                                isPaymentPlanDetails,
                                item?.status === 'Returned',
                            ) }>
                                { Object.entries(columns).map((
                                    [key, value],
                                ) => (
                                    <TableCell
                                        key={ key }
                                        width={ value.width || 'inherit' }
                                        sx={ styles.tableCell(
                                            theme,
                                            isPaymentPlanDetails,
                                            item[key],
                                        ) }
                                    >
                                        { item[key] }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                        { expandable && isCollapsable && !exp && <TableRow>
                            <TableCell
                                width={ 'inherit' }
                                sx={ styles.tableCell }
                            >
                                { '...' }
                            </TableCell>
                            <TableCell
                                width={ 'inherit' }
                                sx={ styles.tableCell }
                            >
                                { '...' }
                            </TableCell>
                            <TableCell
                                width={ 'inherit' }
                                sx={ styles.tableCell }
                            >
                                { '...' }
                            </TableCell>
                        </TableRow> }
                    </TableBody>
                    : <TableBody>
                        <TableRow>
                            <TableCell colSpan={ 100 }>
                                { isPaymentSchedule
                                    ? 'Verify your identity first!'
                                    : 'No loans found!'
                                }
                            </TableCell>
                        </TableRow>
                    </TableBody>
                }
            </Table>
            { expandable && isCollapsable && <Collapse
                in={ exp }
                onEntered={ () => {
                    if (isESigPaymentSchedule === true) {
                        fireGtmEvent(GtmEvent.APPLY_3_ESIG_SCHEDULE_EXPANDED);
                    }
                }}
                onExited={ () => {
                    if (isESigPaymentSchedule === true) {
                        fireGtmEvent(GtmEvent.APPLY_3_ESIG_SCHEDULE_COLLAPSED);
                    }
                }}
            >
                <Table>
                    <colgroup>
                        <col style={{ width:'30%' }}/>
                        <col style={{ width:'30%' }}/>
                        <col style={{ width:'40%' }}/>
                    </colgroup>
                    <TableBody>
                        { data.slice(3).map((item, index) => (
                            <TableRow key={ index } >
                                { Object.entries(columns).map((
                                    [key, value],
                                ) => (
                                    <TableCell
                                        key={ key }
                                        width={ value.width || 'inherit' }
                                        sx={ styles.tableCell }
                                    >
                                        { item[key] }
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Collapse> }
        </TableContainer>
        { expandable && isCollapsable && <Box sx={ styles.expButtonContainer }>
            <Box sx={ styles.expButton } onClick={ () => {
                setExp(() => !exp);
                setTimeout(() => { onExpand?.(!exp); }, 350);
            }}>
                <Typography sx={ styles.expButtonText as any }>
                    { exp ? 'Collapse' : 'Expand' } schedule
                </Typography>
                { !exp ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon /> }
            </Box>
        </Box> }
    </Fragment>;
};

export default DataTable;
