import { FunctionComponent } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        paddingTop: '35px',
    },
    title: (theme: Theme) => ({
        ...theme.typography.h3,
        lineHeight: '38px',
        color: theme.palette.common.white,
    }),
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: '16px',
    },
};

export interface ProfileTitleContentProps {
    title: string;
    icon: JSX.Element;
}

const ProfileTitleContent: FunctionComponent<ProfileTitleContentProps>
    = props =>
{
    return <Box sx={ styles.container }>
        <Typography component="h3" sx={ styles.title }>
            { props.title }
        </Typography>
        <Box sx={ styles.iconContainer }>
            { props.icon }
        </Box>
    </Box>;
};

export default ProfileTitleContent;
