import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function HistoryIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 25 25'}
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
        >
            <path
                d={
                    'M9.37565 4.621H14.6257V2.0835H9.37565V4.621ZM19.0007 24.6293C17.4257 24.6293 16.0937 24.0849 15.0048 22.996C13.9159 21.9071 13.3715 20.5752 13.3715 19.0002C13.3715 17.4252 13.9159 16.0932 15.0048 15.0043C16.0937 13.9154 17.4257 13.371 19.0007 13.371C20.5757 13.371 21.9076 13.9154 22.9965 15.0043C24.0854 16.0932 24.6298 17.4252 24.6298 19.0002C24.6298 20.5752 24.0854 21.9071 22.9965 22.996C21.9076 24.0849 20.5757 24.6293 19.0007 24.6293ZM21.159 21.946L21.9465 21.1585L19.4965 18.9418V15.3252H18.2715V19.2918L21.159 21.946ZM12.5257 22.5002H2.08398C1.59787 22.5002 1.18468 22.33 0.844401 21.9897C0.504123 21.6495 0.333984 21.2363 0.333984 20.7502V6.371C0.333984 5.88489 0.504123 5.47169 0.844401 5.13141C1.18468 4.79114 1.59787 4.621 2.08398 4.621H7.62565V2.0835C7.62565 1.59739 7.79579 1.18419 8.13607 0.843913C8.47635 0.503635 8.88954 0.333496 9.37565 0.333496H14.6257C15.1118 0.333496 15.525 0.503635 15.8652 0.843913C16.2055 1.18419 16.3757 1.59739 16.3757 2.0835V4.621H21.9173C22.4034 4.621 22.8166 4.79114 23.1569 5.13141C23.4972 5.47169 23.6673 5.88489 23.6673 6.371V13.3127C23.0062 12.7682 22.277 12.3502 21.4798 12.0585C20.6826 11.7668 19.8562 11.621 19.0007 11.621C16.9395 11.621 15.1944 12.3356 13.7652 13.7647C12.3361 15.1939 11.6215 16.9391 11.6215 19.0002C11.6215 19.6224 11.6993 20.2252 11.8548 20.8085C12.0104 21.3918 12.234 21.9557 12.5257 22.5002Z'
                }
                fill={ props.fill || theme.palette.text.primary}
            />
        </SvgIcon>
    );
}

export default HistoryIcon;
