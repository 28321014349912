import React from 'react';
import Box from '@mui/material/Box';
import MobileHeader from '../../components/Header/Mobile';
import MobileFooter from './MobileFooter';
import { ContentLayoutProps } from '../BaseLayout';
import { Theme } from '@mui/material/styles';
import { isLight } from '../../common/helpers';

const styles = {
    mobile: (theme: Theme) => ({
        backgroundColor: isLight(theme)
            ? theme.palette.common.white
            : theme.palette.secondary.dark
        ,
    }),
    content: {
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },
    footer: {
        paddingLeft: '20px',
        paddingRight: '20px',
        paddingBottom: '30px',
        marginTop: 'auto',
    },
};

const MobileLayout: React.FunctionComponent<ContentLayoutProps> = props => {
    // TODO: need to improve layout with executable props, will do in
    //      parallel with developing pages
    return <Box sx={ styles.mobile } >
        <Box sx={ styles.content } >
            <MobileHeader { ...props } />
            { props.children }
            { !props.withoutMobileFooter && <Box sx={ styles.footer }>
                <MobileFooter/>
            </Box> }
        </Box>
    </Box>;
};

export default MobileLayout;
