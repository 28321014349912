import React, { createContext } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Theme, useTheme } from '@mui/material/styles';
import Head from 'next/head';
import MobileLayout from './Mobile';
import DesktopLayout from './Desktop';
import { KnownBreakpoints } from '../../common/constants';
import { ContentLayoutProps } from '../BaseLayout';
import OfflineError from '../../components/OfflineError';

export const SellLeadContext = createContext<any>({});

const PageLayout: React.FunctionComponent<
    ContentLayoutProps & {
        networkError?: boolean;
        setNetworkError?: (error: boolean) => void;
    }
> = props => {
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );

    return <React.Fragment>
        { props.networkError &&
            <OfflineError onClose={ () => props.setNetworkError!(false) } />
        }
        <Head>
            <title>{ props.title }</title>
        </Head>
        { !isMobile
            ? <DesktopLayout { ...props } />
            : <MobileLayout { ...props } />
        }
    </React.Fragment>;
};

export default PageLayout;
