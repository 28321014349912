import React, { FunctionComponent, useEffect, useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageLayout from '../../../../layouts/PageLayout';
import ProfileTitleContent from '../ProfileTitleContent';
import HistoryIcon from '../../../../assets/SvgIcons/HistoryIcon';
import ProfileDesktopLayout from '../ProfileDesktopLayout';
import { ProfilePageLink } from '../../../Form/Links';
import DataTable, { DataTableProps } from '../../../DataTable';
import { KnownBreakpoints } from '../../../../common/constants';
import DocumentLink from '../../Account/DocumentLink';
import { ApiClient, isLight, RestMethod } from '../../../../common/helpers';
import ConsumerYearMobileInfo
    from '../../../../layouts/PageLayout/ConsumerYearMobileInfo';
import { DialogSupportInfoMobile } from '../../../DialogSide/DialogSupportInfo';
import PageLoader from '../../PageLoader';
import useAuthorized from '../../../../common/hooks/useAuthorized';
import { formatDateTime, formatMoney } from '../../../../common/helpers/format';
import LoanAgreementModal from '../../LoanAgreementModal';
import { useCustomer } from '../../../../common/hooks/AccountInfoProvider';
import { LoanStatus } from '../../../../common/types/enums';
import { Customer } from '../../../../common/types/Customer';
import asyncLogger from '../../../../common/logger';

const getColor = (theme: Theme): string => isLight(theme)
    ? theme.palette.text.primary
    : theme.palette.secondary.dark;

const styles = {
    container: {
        paddingTop: '33px',
    },
    sidebar: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '390px',
        paddingRight: '135px',
        position: 'sticky',
        top: '224px',
        height: '100%',
        [theme.breakpoints.down(KnownBreakpoints.laptop)]: {
            paddingRight: '20px',
        },
    }),
    contentWrapper: {
        padding: '0 20px',
    },
    content: (theme: Theme) => ({
        [theme.breakpoints.up(KnownBreakpoints.tablet)]: {
            marginTop: '6px',
        },
    }),
    titleText: (theme: Theme) => ({
        ...theme.typography.h4,
        lineHeight: '28.13px',
        color: theme.palette.text.primary,
        marginBottom: '26px',
    }),
    icon: {
        fontSize: '26px',
    },
    table: (theme: Theme) => ({
        maxWidth: '785px',
        [theme.breakpoints.up(KnownBreakpoints.tablet)]: {
            marginBottom: '100px',
        },
    }),
    linkContainer: {
        padding: '29px 0 33px',
    },
    text: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '22px',
        color: theme.palette.text.primary,
        marginTop: '20px',
    }),
    footer: (theme: Theme) => ({
        height: '100%',
        padding: '50px 20px 20px 20px',
        backgroundColor: isLight(theme)
            ? theme.palette.action.selected
            : theme.palette.info.main,
    }),
    divider: (theme: Theme) => ({
        width: '100%',
        borderColor: isLight(theme)
            ? theme.palette.divider
            : theme.palette.secondary.dark,
        marginBottom: '22px',
    }),
    company: {
        margin: '13px 0 0',
    },
};

interface LoanHistoryItem {
    id: string | number;
    createdAt: string;
    status: string;
    amount: string;
    link?: string;
}

interface ContentProps extends DataTableProps {
    isMobile?: boolean;
    text: string;
}

interface LoanDocumentLinkProps {
    id: string;
    title: string;
    authorized: boolean;
    customerId?: string;
}

const SideBar = () => <Box sx={ styles.sidebar } >
    <ProfilePageLink />
</Box>;

const Content: FunctionComponent<ContentProps> = (props) => {
    return <Box sx={ styles.content } >
        { !props.isMobile &&
            <Typography component="h4" sx={ styles.titleText }>
                { props.text }
            </Typography>
        }

        <Box sx={ styles.table }>
            <DataTable data={ props.data } columns={ props.columns } />
        </Box>

        { props.isMobile &&
            <Typography component="p" sx={ styles.text }>
                { props.text }
            </Typography>
        }
    </Box>;
};

const Footer = () => {
    const theme = useTheme<Theme>();

    return <Box sx={ styles.footer } >
        <DialogSupportInfoMobile />
        <Divider sx={ styles.divider } />
        <Box sx={ styles.company }>
            <ConsumerYearMobileInfo color={ getColor(theme) } />
        </Box>
    </Box>;
};

const LoanDocumentLink: FunctionComponent<LoanDocumentLinkProps> = props => {
    const { authorized, title, id, customerId } = props;
    const [open, setOpen] = React.useState(false);
    const [documentShow, setDocumentShow] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleClick = async(): Promise<void> => {
        if (!authorized) {
            return ;
        }

        setDocumentShow(true);
        handleOpen();
    };

    return <React.Fragment>
        <DocumentLink
            type='loanDocuments'
            title={ title }
            onClick={ handleClick }
        />
        { documentShow && <LoanAgreementModal
            open={ open }
            onClose={ handleClose }
            title={ 'Loan Agreement' }
            loanId={ id }
            customerId={ customerId }
        /> }
    </React.Fragment>;
};

const getLoanHistory = (customer: Customer): any[] => {
    return customer.loans
        .filter(loan => loan.status === LoanStatus.Paid)
        .map(loan => ({
            ...loan,
            amount: formatMoney(loan.amount),
            createdAt: formatDateTime(loan.createdAt),
        }))
        .sort((a, b) => +b.id - +a.id);
};

const LoanHistoryPage: FunctionComponent = () => {
    const authorized = useAuthorized();
    const theme = useTheme<Theme>();
    const isMobile = useMediaQuery(
        theme.breakpoints.down(KnownBreakpoints.tablet),
    );
    // fix header title for given range
    const isHeaderFix = useMediaQuery(theme.breakpoints.between(391, 500));
    const customer = useCustomer();
    const columns = {
        id: { title: 'Loan #' },
        createdAt: { title: 'Created at' },
        status: { title: 'Status' },
        amount: { title: 'Amount' },
        link: {
            title: isHeaderFix ? 'Doc' : 'Link to documents',
            width: isMobile ? '70px' : '200px',
        },
    };
    const title = 'Loan history';
    const text = 'This list shows loans that were financed and paid';
    const iconColor = theme.palette.common.white;
    const icon = <HistoryIcon fill={ iconColor } sx={ styles.icon } />;

    const linkedData = (data: LoanHistoryItem[]) => data.map(item => ({
        ...item,
        link: <LoanDocumentLink
            id={ String(item.id) }
            title={ isHeaderFix ? '' : 'Loan documents' }
            authorized={ authorized }
            customerId={ customer?.id }
        />,
    }));

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any[] | undefined>(
        customer?.loans?.length ? getLoanHistory(customer) : undefined,
    );

    useEffect(() => {
        if (!customer?.id) {
            return;
        }

        (async () => {
            setLoading(true);

            try {
                const { data: history } = await ApiClient.request<{
                    data: { customer: Customer };
                }>('/loanHistory', {
                    method: RestMethod.GET,
                    query: { customerId: customer.id },
                });

                if (history?.customer) {
                    setData(getLoanHistory(history.customer));
                }
            } catch (err) {
                asyncLogger.error(err);
            }

            setLoading(false);
        })();
        // eslint-disable-next-line
    }, [customer]);

    return isMobile
        ? (!loading ? <PageLayout
            title={ title }
            withActions={ false }
            footer={ <Footer /> }
            hideHeaderBorder
        >
            { data && <Box sx={ styles.contentWrapper }>
                <Box sx={ styles.linkContainer }>
                    <ProfilePageLink />
                </Box>
                <Content
                    data={ linkedData(data) }
                    columns={ columns }
                    text={ text }
                    isMobile={ true }
                />
            </Box> }
        </PageLayout> : <PageLoader />)

        : (!loading ? <PageLayout
            title={ title }
            withActions={ false }
            headerBottomSticky={{
                element: <ProfileTitleContent title={ title } icon={ icon } />,
                zIndex: 1000,
            }}
        >
            <Box sx={ styles.container }>
                { data && <React.Fragment>
                    <ProfileDesktopLayout
                        sidebar={ <SideBar/> }
                        content={
                            <Content
                                text={ text }
                                data={ linkedData(data) }
                                columns={ columns }
                            />
                        }
                    />
                </React.Fragment> }
            </Box>
        </PageLayout> : <PageLoader />);
};

export default LoanHistoryPage;
