import { FunctionComponent } from 'react';
import Box from '@mui/material/Box';

const styles = {
    container: {
        display: 'flex',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
};

export interface ProfileDesktopLayoutProps {
    sidebar: JSX.Element;
    content: JSX.Element;
}

const ProfileDesktopLayout: FunctionComponent<
    ProfileDesktopLayoutProps
    > = props => {
    return <Box sx={ styles.container }>
        { props.sidebar }
        <Box sx={ styles.content }>
            { props.content }
        </Box>
    </Box>;
};

export default ProfileDesktopLayout;
