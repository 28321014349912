import React, { FunctionComponent } from 'react';
import Typography from '@mui/material/Typography';
import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../common/helpers';
import OfficeInfo, { OfficeInfoDesktop } from '../SupportInfo/OfficeInfo';
import { useConfig } from '../../common/hooks/ConfigProvider';
import useMediaQuery from '@mui/material/useMediaQuery';
import { KnownBreakpoints } from '../../common/constants';
import SmartLink from '../SmartLink';

const getTextColor = (theme: Theme): string => isLight(theme)
    ? theme.palette.text.primary
    : theme.palette.secondary.dark;

const styles = {
    mobileTitle: (theme: Theme) => ({
        ...theme.typography.h5 as any,
        lineHeight: '22px',
        color: getTextColor(theme),
        marginBottom: '10px',
    }),
    desktopTitle: (theme: Theme) => ({
        ...theme.typography.h6,
        lineHeight: '20px',
        color: getTextColor(theme),
        marginBottom: '20px',
    }),
    mobileTextInfo: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '16.41px',
        color: getTextColor(theme),
        marginBottom: '15px',
    } as any),
    desktopTextInfo: (theme: Theme) => ({
        fontSize: theme.typography.fontSize,
        fontWeight: theme.typography.fontWeightRegular,
        lineHeight: '22px',
        color: getTextColor(theme),
        marginBottom: '15px',
    }),
    officeInfo: (theme: Theme) => ({
        marginBottom: '0 !important',
        marginTop: '0 !important',
        fontWeight: theme.typography.fontWeightRegular + ' !important',
        display: 'block !important',
        textAlign: 'left !important',
        '& > p, & > span > span': {
            fontSize: '14px !important',
            display: 'inline-block !important',
            color: (isLight(theme)
                    ? theme.palette.text.primary
                    : theme.palette.secondary.dark
            ) + ' !important',
            lineHeight: '22px !important',
        },
        '& > span > span': {
            paddingTop: '8px !important',
        },
    }),
    link: (theme: Theme) => ({
        cursor: 'pointer',
        color: getTextColor(theme),
        textDecorationColor: getTextColor(theme),
    }),
};

export const DialogSupportInfoDesktop: FunctionComponent = () => {
    const theme = useTheme<Theme>();
    const { consumerOperationsDetails } = useConfig();
    const { supportEmail, phoneNumber } = consumerOperationsDetails as any;

    return <React.Fragment>
        <Typography sx={ styles.desktopTitle }>
            Have&nbsp;questions? Contact&nbsp;us!
        </Typography>
        <Typography sx={ styles.desktopTextInfo }>
            Phone: <SmartLink
                href={ `tel:${ phoneNumber }` }
                style={ (styles.desktopTextInfo as any)(theme)}
            >
                { phoneNumber }
            </SmartLink>
        </Typography>
        <Typography sx={ styles.desktopTextInfo }>
            Email: <SmartLink
                style={ (styles.link as any)(theme) }
                href={ `mailto:${ supportEmail }` }
            >
                { supportEmail }
            </SmartLink>
        </Typography>
        <OfficeInfoDesktop styles={ styles.officeInfo(theme) } />
    </React.Fragment>;
};

export const DialogSupportInfoMobile: FunctionComponent<{
    withoutCenteredOfficeStatus?: boolean,
}> = props => {
    const theme = useTheme<Theme>();
    const { consumerOperationsDetails } = useConfig();
    const { supportEmail, phoneNumber } = consumerOperationsDetails as any;

    return <React.Fragment>
        <Typography sx={ styles.mobileTitle }>
            Have&nbsp;questions? Contact&nbsp;us!
        </Typography>
        <Typography sx={ styles.mobileTextInfo }>
            Email: <SmartLink
                style={ (styles.link as any)(theme) }
                href={ `mailto:${ supportEmail }` }
            >
                { supportEmail }
            </SmartLink>
        </Typography>
        <Typography sx={ styles.mobileTextInfo }>
            Phone: <SmartLink
                href={ `tel:${ phoneNumber }` }
                style={ (styles.mobileTextInfo as any)(theme)}
            >
                { phoneNumber }
            </SmartLink>
        </Typography>
        <OfficeInfo
            styles={ styles.officeInfo(theme) }
            withoutCenteredOfficeStatus={ props.withoutCenteredOfficeStatus }
        />
    </React.Fragment>;
};

const DialogSupportInfo: FunctionComponent<{
    withoutCenteredOfficeStatus?: boolean,
}> = props => {
    const theme = useTheme<Theme>();
    const isDesktop = useMediaQuery(
        theme.breakpoints.up(KnownBreakpoints.laptop),
    );

    return isDesktop
        ? <DialogSupportInfoDesktop />
        : <DialogSupportInfoMobile
            withoutCenteredOfficeStatus={ props.withoutCenteredOfficeStatus }
        />
    ;
};

export default DialogSupportInfo;
