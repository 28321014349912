import { Theme, useTheme } from '@mui/material/styles';
import { isLight } from '../../common/helpers';
import React, { FunctionComponent } from 'react';
import { NamedRoute } from '../../common/hooks/useNamedRoute';
import { useConfig } from '../../common/hooks/ConfigProvider';
import SmartLink from '../SmartLink';
import Typography from '@mui/material/Typography';

const styles = {
    linkWrapper: (theme: Theme) => ({
        display: 'inline-block',
        margin: '5px 0',
        color: isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.info.light,
    }),
    link: (theme: Theme) => ({
        color: isLight(theme)
            ? theme.palette.secondary.dark
            : theme.palette.info.light
        ,
        textTransform: 'none',
        fontSize: theme.typography.fontSize,
        minWidth: 'unset',
        lineHeight: '16.41px',
        cursor: 'pointer',
        textDecoration: 'underline',
        '&:hover': {
            color: isLight(theme)
                ? theme.palette.secondary.dark
                : theme.palette.info.light,
            textDecoration: 'none',
        },
    }),
};

export interface StaticLinkProps {
    text?: string;
}

export const LoginPageLink: FunctionComponent<StaticLinkProps> = props => {
    const { text } = props;
    const theme = useTheme();
    const style = styles.link(theme) as React.CSSProperties;

    return <SmartLink
        style={ style }
        href={ NamedRoute.LOGIN }
        aria-label="go to login page"
    >
        { text || 'Go back to Login page' }
    </SmartLink>;
};

export const HomePageLink: FunctionComponent<StaticLinkProps> = props => {
    const { text } = props;
    const theme = useTheme();
    const style = styles.link(theme) as React.CSSProperties;
    const { consumerName } = useConfig();

    return <SmartLink style={ style } href="/" aria-label="go to home page">
        { text || `Go to ${ consumerName } site` }
    </SmartLink>;
};

export const RemindPasswordLink: FunctionComponent<StaticLinkProps> = props => {
    const { text } = props;
    const theme = useTheme();
    const style = styles.link(theme) as React.CSSProperties;

    return <SmartLink
        style={ style }
        href={ NamedRoute.PASSWORD_REMIND }
        aria-label="go to remind password"
    >
        { text || 'I don\'t remember my password' }
    </SmartLink>;
};

export const RegisterAccountLink: FunctionComponent<
    StaticLinkProps
> = props => {
    const { text } = props;
    const theme = useTheme();
    const style = styles.link(theme) as React.CSSProperties;

    return <SmartLink
        style={ style }
        href={ NamedRoute.CREATE_APPLICATION }
        aria-label="create account"
    >
        { text || 'I don\'t have an account' }
    </SmartLink>;
};

export const ProfilePageLink: FunctionComponent<StaticLinkProps> = props => {
    const { text } = props;
    const theme = useTheme();
    const style = styles.link(theme) as React.CSSProperties;

    return <Typography component="span" sx={ styles.linkWrapper }>
        &lArr;&nbsp;<SmartLink
            style={ style }
            href={ NamedRoute.PROFILE }
            aria-label="go to profile page"
        >
            { text || 'Back to Profile information' }
        </SmartLink>
    </Typography>;
};

export const BackToAccountLink: FunctionComponent<StaticLinkProps> = props => {
    const { text } = props;
    const theme = useTheme<Theme>();

    return <Typography component="span" sx={ styles.linkWrapper }>
        &lArr;&nbsp;<SmartLink
            href={ NamedRoute.HOME }
            style={ (styles.link as any)(theme, true) }
        >
            { text || 'Back to account' }
        </SmartLink>
    </Typography>;
};
